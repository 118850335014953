@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600&family=Spartan:wght@500;700&display=swap");

body {
  margin: 0;
  font-family: "Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scoped scrollbar styles */
.sidebar-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.sidebar-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.sidebar-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.sidebar-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
